import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {navigate} from 'gatsby';
import {Col, Row} from 'react-bootstrap';
import {GET_HEXOMATIC_USER} from '../../graphql/queries';
import Layout from '../../components/layout/layout';
import Section from '../../components/section';
import PricingPackeges from '../../components/pricing/pricingPackeges';
import UserWrapper from '../../components/userWrap';
import Button from '../../components/button';
import SubscriptionModal from '../../components/subscriptionModal';
import shuttle from '../../images/svgIcons/shuttle.svg';
import homeMailImgLg from '../../../static/promo-5-year-journey.jpg';
import homeMailImgSm from '../../images/home/illustrations/web-scraping-automation-sm.png';
import {getPromoDate, getShowModalonClick, getUserExists, setShowModalonClick} from '../../helpers';
import Counter from '../../components/countdown';
import TrustedSection from '../../components/trusted-section';
import '../../components/home/styles.scss';
import {useMediaQuery} from 'react-responsive';
import left from '../../images/home/left.svg';
import right from '../../images/home/right.svg';
import {openCalendly} from '../../helpers/trackingEvents';
import './styles.scss';

const PricingPromo = ({pageContext}: any) => {
  const [annual, setAnnual] = useState(true);
  const ltd = false;
  const [promo, setPromo] = useState(false);
  const [show, setShow] = useState(false);

  // const {data: hexomaticUserInfo} = useQuery(GET_HEXOMATIC_USER, {
  //   fetchPolicy: 'no-cache',
  // });

  useEffect(() => {
    navigate('/pricing/');
    // if (hexomaticUserInfo?.User?.get && !hexomaticUserInfo?.User?.get?.email) {
    //   navigate('/login/');
    // } else if (
    //   (hexomaticUserInfo?.HexomaticUser?.getByLegacyId?.pricing_package &&
    //     hexomaticUserInfo.HexomaticUser.getByLegacyId.pricing_package !== 'FREE' &&
    //     hexomaticUserInfo.HexomaticUser.getByLegacyId.pricing_package_interval !== 'LTD') ||
    //   (hexomaticUserInfo?.User?.get?.email && !hexomaticUserInfo?.User?.get?.promoData?.hexowatch_ltd)
    // ) {
    //   navigate('/pricing/');
    // }
  }, []);

  const isMobileSm = useMediaQuery({maxWidth: 380});

  const textsImageLeft = [
    {label: 'Wasting time on manual work', top: isMobileSm ? '-2px' : '0px', left: isMobileSm ? '100px' : '120px'},
    {label: 'Copy-pasting all day', top: isMobileSm ? '46px' : '58px', left: isMobileSm ? '56px' : '70px'},
    {label: 'Hiring programmers', top: isMobileSm ? '46px' : '58px', left: isMobileSm ? '186px' : '226px'},
    {label: 'Outsourcing to VA’s', top: isMobileSm ? '94px' : '116px', left: isMobileSm ? '36px' : '42px'},
    {
      label: 'Subscribing to different services',
      top: isMobileSm ? '94px' : '116px',
      left: isMobileSm ? '156px' : '189px',
    },
    {label: 'Procrastinating on tasks', top: isMobileSm ? '142px' : '174px', left: isMobileSm ? '54px' : '66px'},
    {label: 'Complex enterprise tools', top: isMobileSm ? '142px' : '174px', left: isMobileSm ? '194px' : '233px'},
    {label: 'Expensive consultants', top: isMobileSm ? '190px' : '232px', left: isMobileSm ? '110px' : '132px'},
  ];
  const textsImageRight = [
    {label: 'Capture data from any website', top: isMobileSm ? '16px' : '18px', left: isMobileSm ? '60px' : '74px'},
    {label: 'Automate  tedious tasks', top: isMobileSm ? '90px' : '98px', left: isMobileSm ? '60px' : '74px'},
    {label: 'Scale your work on autopilot', top: isMobileSm ? '166px' : '178px', left: isMobileSm ? '60px' : '74px'},
  ];

  return (
    <Layout pricingPageLtd noBanner>
      <div className="pricing_promo_page home-container">
        <section className="pc-section-dark"></section>
        <section className="pc-section-text">
          <div className="text-center section-content my-5" style={{maxWidth: '900px', margin: 'auto'}}>
            <div className="text-center" style={{color: 'rgba(0, 0, 0, 0.87)'}}>
              Time flies when you're <strong>automating and monitoring like a pro!</strong>
            </div>
            <div className="mb-4">
              It's been <strong>5 incredible years</strong> since we started our scraping and automation journey, and
              it’s all thanks to you ❤️!
            </div>
            <div className="text-center" style={{color: 'rgba(0, 0, 0, 0.87)', marginBottom: '20px'}}>
              To show our immense appreciation, you’re invited to an exclusive anniversary promo for our early adopters{' '}
              <strong>(please don’t share on social media)</strong>. 
            </div>
            <div className="text-center" style={{color: 'rgba(0, 0, 0, 0.87)', marginBottom: '20px'}}>
              For the <strong>next 72 hours only</strong>, get your hands on Hexomatic or Hexowatch (or upgrade your
              current plan) for an unbelievable <strong>one-time price of just $99</strong> for lifetime access! 🎉
            </div>
            <Counter date={'May 17 2024 07:00:00 GMT'} title="DEAL ENDS IN..." className="lg" />
          </div>
        </section>
        {/* <Section className="pricing-section">
          <div className="pricing-section mx-auto mt-5 mb-4">
            <PricingPackeges
              interval={ltd ? 'ltd' : annual ? 'annual' : 'monthly'}
              products={pageContext.products}
              promo={promo}
              LTD
              promoPage
              specialLtd
            />
          </div>
        </Section> */}
        <Section className="py-40">
          <Col>
            <div className="section-part">
              <h2 className="title">
                <p className="concept-title">SKYROCKET YOUR PRODUCTIVITY</p>
                Your <span className="primary-color">virtual assistant</span>
                <br />
                that never sleeps
              </h2>

              <div className="row w-100" style={{maxWidth: '1000px'}}>
                <div className="col-12 col-lg-5 p-0">
                  <div className="mb-4">
                    <div className="concept-title">THE OLD WAY</div>
                    <div className="concept-title-dark">Drowning in tasks</div>
                  </div>

                  <div className="position-relative" style={{maxWidth: isMobileSm ? '300px' : '360px', margin: 'auto'}}>
                    <img
                      src={left}
                      alt="Drowning in tasks"
                      className="justify-content-center image-styles"
                      style={{
                        maxWidth: isMobileSm ? '300px' : '360px',
                        margin: 'auto',
                        width: isMobileSm ? '300px' : '100%',
                      }}
                      width={isMobileSm ? 300 : 360}
                      height={290}
                    />
                    {textsImageLeft.map(item => (
                      <div
                        style={{
                          position: 'absolute',
                          top: item.top,
                          left: item.left,
                          fontSize: isMobileSm ? '7px' : '8.7px',
                          color: '#516077',
                          fontWeight: 500,
                        }}
                      >
                        {item.label}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-12 col-lg-2 d-flex p-0">
                  <div className="vs-line-main mx-auto mt-auto"></div>
                </div>

                <div className="col-12 col-lg-5 p-0">
                  <div className="mb-4">
                    <div className="concept-title">THE NEW WAY</div>
                    <div className="concept-title-dark">10X your productivity</div>
                  </div>
                  <div className="position-relative" style={{maxWidth: isMobileSm ? '300px' : '320px', margin: 'auto'}}>
                    <img
                      src={right}
                      alt="10X your productivity"
                      className="justify-content-center image-styles"
                      style={{maxWidth: '320px', margin: 'auto', width: isMobileSm ? '300px' : '100%'}}
                      width={isMobileSm ? 300 : 320}
                      height={260}
                    />
                    {textsImageRight.map(item => (
                      <div
                        style={{
                          position: 'absolute',
                          top: item.top,
                          left: item.left,
                          fontSize: isMobileSm ? '12px' : '14px',
                          color: '#050038',
                          fontWeight: 500,
                        }}
                      >
                        {item.label}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Section>
        <TrustedSection />
      </div>
      {show && <SubscriptionModal handleSetShow={(arg: boolean) => setShow(arg)} isModalOpen={show} />}
    </Layout>
  );
};

export default PricingPromo;
